import React from "react"
import {NewsLayout} from "sections/News/"
import useNewsData from "shared/Hooks/News/useNewsData"
import {TextBlock} from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_31} from "types/News"
import {Video} from '../../sections/News/NewsLayout/Video/video';

const Article = () => {
  const data = useNewsData(NEWS_31);

  return (
    <NewsLayout data={data}>
      <Video data={data.videos.first}/>
      <TextBlock data={data.texts.first}/>
      <TextBlock data={data.texts.second}/>
      <TextBlock data={data.texts.third}/>
      <TextBlock data={data.texts.fourth}/>
      <TextBlock data={data.texts.fifth}/>
    </NewsLayout>
  )
}

export default Article
