import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
       margin-bottom: 24px;
    }
`;

export const Iframe = styled.iframe`
    width: 100% !important;
    height: 500px !important;
    border: none !important;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_big}) {
        height: 400px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 350px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: 250px !important;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        height: 200px !important;
    }
`;
