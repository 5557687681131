import React       from "react"
import {Iframe}    from "./styles";
import {Container} from "./styles"

export const Video = ({data}) => (
  <Container>
    <Iframe src={data.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
  </Container>
)

